import * as React from 'react';
import { Link } from 'gatsby';
import ContentWrap from '../components/ContentWrap';
import { StaticImage } from 'gatsby-plugin-image';
import Button from '../components/Button';
import SEO from '../components/SEO';
import Layout from '../components/Layout';

const NotFoundPage = () => {
  return (
    <Layout>
      <ContentWrap
        style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 400,
        }}>
        <StaticImage width={500} src="../assets/images/404.png" quality={100} alt="" />
        <div>
          <h2>当前页面不存在</h2>
          <div style={{ fontSize: 14 }}>请检查您输入的URL是否正确，或单击下面的按钮返回主页。</div>
          <div style={{ marginTop: 20 }}>
            <Link to="/">
              <Button type="primary">返回首页</Button>
            </Link>
          </div>
        </div>
      </ContentWrap>
    </Layout>
  );
};

export default NotFoundPage;

export function Head() {
  return <SEO title="页面找不到" />;
}
